.container{
    padding: 3vw;
    text-align: center;
    display: flex;
}

.textField{
    width: 300px;
}

.button:focus{
    outline: 0 !important;
}

.button:hover{
    text-decoration: none;
}

.title{
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    color: rgb(85, 85, 85);
    font-size: 3vw;
    text-align: center;
}

.authContainer{
    flex:1
}

.imgContainer{
    flex:1;
}

.image{
    max-width: 35vw;
}