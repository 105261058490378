.laboratoryContainer{
    position: relative;
}

.LaboratoryMicroscope{
    width: 35vw;
    margin-left: 15vw;
    margin-top: 8vw;
}

.listContainer{
    margin-left: 2vw;
}

.expasionPanel{
    width: 30vw;
    position: absolute;
    top: 8vw;
    right: 15vw;
}

.textLaboratory{
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    color: rgb(85, 85, 85);
    font-size: 3vw;
    text-align: center;
    position: absolute;
    top: 1vw;
    width: 60vw;
    left: 20vw;
}

@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    [class*="textLaboratory"] {
        top: 5vw;
        font-size: 6vw;
        width: 70vw;
        left: 15vw;
    }

    [class*="expasionPanel"] {
        width: 60vw;
        left: 20vw;
        top: 20vw;
    }

    [class*="LaboratoryMicroscope"] {
        display: none;
    }

    
}