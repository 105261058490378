.textExchangeRate{
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    color: rgb(85, 85, 85);
    font-size: 3vw;
    text-align: center;
    margin-top: 50px;
}

.container{
    text-align: center;
    margin-top: 50px;
}

.textField{
    width: 300px;
    margin-right: 50px !important;
}

.button{
    height: 50px;
    border-radius: 10px !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}