.container{
    padding-top: 4vw;
    padding-left: 5vw;
    padding-right: 5vw;
}

.addMedicine{
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    font-size: 3vw;
    color: rgb(85, 85, 85);
    text-align: center;
}

.verticalSpace{
    height: 4vw;
}

.media{
    height: 15vw !important;
}

.cardContainer{
    flex: 1;
    margin-left: 10vw;
}

.textField{
    width: 30vw !important;
}
.textFieldPrice{
    width: 15vw !important;
}

.formContainer{
    flex: 1;
    text-align: left;
    margin-left: 10vw;
}

.flexContainer{
    display: flex;
}

.card{
    width: 25vw !important;
}

.button:focus{
    outline: 0 !important;
}

.inputFile{
    display: none;
}

.selectImg{
    cursor:pointer;
    background-color: rgb(0, 91, 165);
    width: 12vw;
    height: auto;
    text-align: center;
    border-radius: 2vw;
    padding: 0.5vw;
    color: white;
    text-transform: uppercase;
    box-shadow: -1px 3px 10px #00000067;
}

.selectImg:hover{
    background-color: rgb(0, 72, 131);
}

.saveMedicineContainer{
    text-align: center;
}