.homeImage{
    width: 100%;
}

.containerInicio{
    position: relative;
    color: white;
}

.textInicio{
    height: 13vw;
    max-width: 55vw;
    width: auto;
    font-size: 4vw;
    font-family: 'Lato', sans-serif;
    position: absolute;
    top: 20%;
    left:10%;
    font-weight: bold;
}

.slogan{
    margin-top: 1.5vw;
    font-size: 2vw;
    font-style: italic;
    font-weight: lighter;
    color: rgb(245, 249, 255);
}

.button{
    margin-top: 3vw !important;
    margin-left: 5vw !important;
    height: 3vw !important;
    width: 13vw;
    font-size: 1vw !important;
    border-radius: 5vw !important;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
}

.button:focus{
    outline: 0 !important;
}

.button:hover{
    text-decoration: none;
    color: inherit;
}

.mainContainer{
    padding-top: 3vw;
    padding-right: 5vw;
    padding-left: 5vw;
}

.servicesTitle{
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    color: rgb(85, 85, 85);
    font-size: 3.5vw;
    text-align: center;
}

/*  SECTIONS  */
.section {
	clear: both;
	padding: 0px;
    margin-top: 3vw;
}

/*  COLUMN SETUP  */
.col {
	display: block;
	float:left;
    margin: 1% 0 1% 5%;
}
.col:first-child { margin-left: 0; }

/*  GROUPING  */
.group:before,
.group:after { content:""; display:table; }
.group:after { clear:both;}
.group { zoom:1; /* For IE 6/7 */ }
/*  GRID OF THREE  */
.span_3_of_3 { width: 100%; }
.span_2_of_3 { width: 65%; }
.span_1_of_3 { width: 30%; }

/*  GO FULL WIDTH BELOW 480 PIXELS */
@media only screen and (max-width: 480px) {
	.col {  margin: 1% 0 1% 0%; }
	.span_3_of_3, .span_2_of_3, .span_1_of_3 { width: 100%; }
}


.action:focus{
    outline: 0 !important;
}

.action:hover{
    text-decoration: none;
    color: inherit;
}

.media{
    height: 15vw !important;
}

.subtitle{
    font-family: 'Lato', sans-serif !important;
    font-size: 1.5vw !important;
}

.contactContainer{
    position: relative;
    margin-top: 5vw;
}

.contactInfo{
    position: absolute;
    font-family: 'Lato', sans-serif;
    top: 8vw;
    right: 15vw;
    width: fit-content;
    height: 50vw;
}
    

.contactTitle{
    text-align: center;
    font-size: 4vw;
    font-weight: bold;
    color: white;
}

.messageImg{
    position: relative;
    margin-top: 4vw;
    height: auto;
    width: fit-content;
}

.messageText{
    position: absolute;
    top: 1.2vw;
    right: 2vw;
    width: fit-content;
    text-align: center;
}

.mapImg{
    position: relative;
    margin-top: 5vw;
    height: auto;
    width: fit-content;
}

.mapText{
    position: absolute;
    top: 1.2vw;
    left: 1vw;
    width: fit-content;
    text-align: center;
}

.clockImg{
    position: relative;
    margin-top: 5vw;
    width: fit-content;
    height: auto;
}

.clockText{
    position: absolute;
    top: 1.2vw;
    right: 5vw;
    width: fit-content;
    text-align: center;
}

.contactImages{
    width: auto;
}

.fromMobileContact{
    position: absolute;
    font-family: 'Lato', sans-serif;
    top: 8vw;
    width: 40vw;
    right: 5vw;
    height: 50vw;
    visibility: hidden;
    text-align: center;
    font-size: 3vw;
}

.contactTitlePhone{
    font-size: 5vw;
    font-weight: bold;
    color: white;
}

.titleDetails{
    font-weight: bold;
    font-size: 4vw !important;
}


.buttonForPhone{
    height: 10vw !important;
    width: 40vw !important;
    font-size: 3vw !important;
    border-radius: 5vw !important;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
    /* margin: 0 auto !important; */
    margin-left: 30vw !important;
    margin-top: 5vw !important;
}


@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    [class*="textInicio"] {
        font-size: 5vw;
    }

    [class*="servicesTitle"] {
        font-size: 8vw;
    }

    [class*="subtitle"] {
        font-size: 4vw !important;
    }

    [class *= "contactInfo"] {
        display: none;
    }

    [class *="fromMobileContact"] {
        visibility: visible;
    }

    [class *="containerInicio"] {
        display: none;
    }

    [class *="displayForPhone"] {
        display:inline
    }
  }