.verticalSpace{
    height: 3vw;
}

.title{
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    color: rgb(85, 85, 85);
    font-size: 3vw;
    text-align: center;
    margin-top: 3vw;
}

@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    [class*="title"] {
        font-size: 6vw;
    }

  }