.grid{
    flex-Grow: 1;
}

.container{
    margin-top: 3vw;
}

.inputGroup{
    text-align: center;
}

.searchBar{
    width: 400px;
    -webkit-transition: 0.5s !important;
    transition: 0.5s !important;
    outline: none !important;
}


.spacingDiv{
    height: 4vw;
}

@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    [class*="searchBar"] {
        width: fit-content;
    }

  }