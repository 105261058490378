.media{
    height: 20vw !important;
}

.subtitle{
    font-family: 'Lato', sans-serif !important;
    font-size: 1.5vw !important;
}

.price{
    font-family: 'Lato', sans-serif !important;
    font-size: 1vw !important;
}

.description{
    font-family: 'Lato', sans-serif !important;
    font-size: 1vw !important;
}

.textField{
    width: 100% !important;
}

.textFieldPrice{
    width: 50% !important;
}

.selectDiscount{
    width: 100px;
}

.card{
    width: 20vw !important;
}

.cardAction:focus{
    outline: 0 !important;
}

.cardAction:hover{
    text-decoration: none;
    color: inherit;
}

.verticalSpace{
    height: 3vw;
}

.inputFile{
    display: none;
}

.selectImg{
    cursor:pointer;
    background-color: rgb(0, 91, 165);
    width: 12vw;
    height: auto;
    text-align: center;
    border-radius: 2vw;
    padding: 0.5vw;
    color: white;
    text-transform: uppercase;
    box-shadow: -1px 3px 10px #00000067;
}

.selectImg:hover{
    background-color: rgb(0, 72, 131);
}

.imageName{
    font-family: 'Lato', sans-serif;
    margin: 20px;
}

.discount{
    background-color: rgb(255, 62, 62);
    color: white;
    margin-top: 1vw;
    width: 50px;
    float: right;
    margin-right: 1vw;
    text-align: center;
    border-radius: 10px;
    padding: 5px;
}

@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    [class*="card"] {
        width: 60vw !important;
    }

    [class*="media"] {
        height: 50vw !important;
    }

    [class*="subtitle"] {
        font-size: 4vw !important;
    }

    [class*="price"] {
        font-size: 2vw !important;
    }

    [class*="description"] {
        font-size: 3vw !important;
    }
  }