@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);

.home_homeImage__1AkzX{
    width: 100%;
}

.home_containerInicio__36ffO{
    position: relative;
    color: white;
}

.home_textInicio__2LUua{
    height: 13vw;
    max-width: 55vw;
    width: auto;
    font-size: 4vw;
    font-family: 'Lato', sans-serif;
    position: absolute;
    top: 20%;
    left:10%;
    font-weight: bold;
}

.home_slogan__3-zek{
    margin-top: 1.5vw;
    font-size: 2vw;
    font-style: italic;
    font-weight: lighter;
    color: rgb(245, 249, 255);
}

.home_button__1LeQf{
    margin-top: 3vw !important;
    margin-left: 5vw !important;
    height: 3vw !important;
    width: 13vw;
    font-size: 1vw !important;
    border-radius: 5vw !important;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
}

.home_button__1LeQf:focus{
    outline: 0 !important;
}

.home_button__1LeQf:hover{
    text-decoration: none;
    color: inherit;
}

.home_mainContainer__SJ1qN{
    padding-top: 3vw;
    padding-right: 5vw;
    padding-left: 5vw;
}

.home_servicesTitle__-yO0S{
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    color: rgb(85, 85, 85);
    font-size: 3.5vw;
    text-align: center;
}

/*  SECTIONS  */
.home_section__3Z6m1 {
	clear: both;
	padding: 0px;
    margin-top: 3vw;
}

/*  COLUMN SETUP  */
.home_col__2HTRa {
	display: block;
	float:left;
    margin: 1% 0 1% 5%;
}
.home_col__2HTRa:first-child { margin-left: 0; }

/*  GROUPING  */
.home_group__36Slf:before,
.home_group__36Slf:after { content:""; display:table; }
.home_group__36Slf:after { clear:both;}
.home_group__36Slf { zoom:1; /* For IE 6/7 */ }
/*  GRID OF THREE  */
.home_span_3_of_3__fTmgi { width: 100%; }
.home_span_2_of_3__DQhO0 { width: 65%; }
.home_span_1_of_3__F_iRB { width: 30%; }

/*  GO FULL WIDTH BELOW 480 PIXELS */
@media only screen and (max-width: 480px) {
	.home_col__2HTRa {  margin: 1% 0 1% 0%; }
	.home_span_3_of_3__fTmgi, .home_span_2_of_3__DQhO0, .home_span_1_of_3__F_iRB { width: 100%; }
}


.home_action__1FGAk:focus{
    outline: 0 !important;
}

.home_action__1FGAk:hover{
    text-decoration: none;
    color: inherit;
}

.home_media__3siLl{
    height: 15vw !important;
}

.home_subtitle__1CWCj{
    font-family: 'Lato', sans-serif !important;
    font-size: 1.5vw !important;
}

.home_contactContainer__3Klw8{
    position: relative;
    margin-top: 5vw;
}

.home_contactInfo__1ShDJ{
    position: absolute;
    font-family: 'Lato', sans-serif;
    top: 8vw;
    right: 15vw;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: 50vw;
}
    

.home_contactTitle__3xC-H{
    text-align: center;
    font-size: 4vw;
    font-weight: bold;
    color: white;
}

.home_messageImg__3_1D4{
    position: relative;
    margin-top: 4vw;
    height: auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.home_messageText__3eO0z{
    position: absolute;
    top: 1.2vw;
    right: 2vw;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    text-align: center;
}

.home_mapImg__3X8QI{
    position: relative;
    margin-top: 5vw;
    height: auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.home_mapText__UELa3{
    position: absolute;
    top: 1.2vw;
    left: 1vw;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    text-align: center;
}

.home_clockImg__NTTqo{
    position: relative;
    margin-top: 5vw;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: auto;
}

.home_clockText__2vaXf{
    position: absolute;
    top: 1.2vw;
    right: 5vw;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    text-align: center;
}

.home_contactImages__3Ro8j{
    width: auto;
}

.home_fromMobileContact__3YQAT{
    position: absolute;
    font-family: 'Lato', sans-serif;
    top: 8vw;
    width: 40vw;
    right: 5vw;
    height: 50vw;
    visibility: hidden;
    text-align: center;
    font-size: 3vw;
}

.home_contactTitlePhone__1xbrB{
    font-size: 5vw;
    font-weight: bold;
    color: white;
}

.home_titleDetails__1wtXc{
    font-weight: bold;
    font-size: 4vw !important;
}


.home_buttonForPhone__3eX0L{
    height: 10vw !important;
    width: 40vw !important;
    font-size: 3vw !important;
    border-radius: 5vw !important;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
    /* margin: 0 auto !important; */
    margin-left: 30vw !important;
    margin-top: 5vw !important;
}


@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    [class*="textInicio"] {
        font-size: 5vw;
    }

    [class*="servicesTitle"] {
        font-size: 8vw;
    }

    [class*="subtitle"] {
        font-size: 4vw !important;
    }

    [class *= "contactInfo"] {
        display: none;
    }

    [class *="fromMobileContact"] {
        visibility: visible;
    }

    [class *="containerInicio"] {
        display: none;
    }

    [class *="displayForPhone"] {
        display:inline
    }
  }
.medicine_media__JGEeB{
    height: 20vw !important;
}

.medicine_subtitle__1qJmE{
    font-family: 'Lato', sans-serif !important;
    font-size: 1.5vw !important;
}

.medicine_price__3GWYj{
    font-family: 'Lato', sans-serif !important;
    font-size: 1vw !important;
}

.medicine_description__2fCCJ{
    font-family: 'Lato', sans-serif !important;
    font-size: 1vw !important;
}

.medicine_textField__oNP_J{
    width: 100% !important;
}

.medicine_textFieldPrice__22the{
    width: 50% !important;
}

.medicine_selectDiscount__2eSoD{
    width: 100px;
}

.medicine_card__fgIfO{
    width: 20vw !important;
}

.medicine_cardAction__392Jf:focus{
    outline: 0 !important;
}

.medicine_cardAction__392Jf:hover{
    text-decoration: none;
    color: inherit;
}

.medicine_verticalSpace__24als{
    height: 3vw;
}

.medicine_inputFile__1v27z{
    display: none;
}

.medicine_selectImg__7Ol6P{
    cursor:pointer;
    background-color: rgb(0, 91, 165);
    width: 12vw;
    height: auto;
    text-align: center;
    border-radius: 2vw;
    padding: 0.5vw;
    color: white;
    text-transform: uppercase;
    box-shadow: -1px 3px 10px #00000067;
}

.medicine_selectImg__7Ol6P:hover{
    background-color: rgb(0, 72, 131);
}

.medicine_imageName__1SELU{
    font-family: 'Lato', sans-serif;
    margin: 20px;
}

.medicine_discount__3eQGx{
    background-color: rgb(255, 62, 62);
    color: white;
    margin-top: 1vw;
    width: 50px;
    float: right;
    margin-right: 1vw;
    text-align: center;
    border-radius: 10px;
    padding: 5px;
}

@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    [class*="card"] {
        width: 60vw !important;
    }

    [class*="media"] {
        height: 50vw !important;
    }

    [class*="subtitle"] {
        font-size: 4vw !important;
    }

    [class*="price"] {
        font-size: 2vw !important;
    }

    [class*="description"] {
        font-size: 3vw !important;
    }
  }
.medicines_grid__2h4-o{
    flex-Grow: 1;
}

.medicines_container__yeMNX{
    margin-top: 3vw;
}

.medicines_inputGroup__3luOX{
    text-align: center;
}

.medicines_searchBar__1QA_R{
    width: 400px;
    transition: 0.5s !important;
    outline: none !important;
}


.medicines_spacingDiv__1DlCV{
    height: 4vw;
}

@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    [class*="searchBar"] {
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
    }

  }
.logIn_container__2-h5G{
    padding: 3vw;
    text-align: center;
    display: flex;
}

.logIn_textField__2brLM{
    width: 300px;
}

.logIn_button__KXqzK:focus{
    outline: 0 !important;
}

.logIn_button__KXqzK:hover{
    text-decoration: none;
}

.logIn_title__2Z6Ed{
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    color: rgb(85, 85, 85);
    font-size: 3vw;
    text-align: center;
}

.logIn_authContainer__3JAUt{
    flex:1 1
}

.logIn_imgContainer__mJF3q{
    flex:1 1;
}

.logIn_image__3B_go{
    max-width: 35vw;
}
.medicinesPrep_verticalSpace__3-6-S{
    height: 3vw;
}

.medicinesPrep_title__dsTZZ{
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    color: rgb(85, 85, 85);
    font-size: 3vw;
    text-align: center;
    margin-top: 3vw;
}

@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    [class*="title"] {
        font-size: 6vw;
    }

  }
.laboratory_laboratoryContainer__1-cKz{
    position: relative;
}

.laboratory_LaboratoryMicroscope__1DX10{
    width: 35vw;
    margin-left: 15vw;
    margin-top: 8vw;
}

.laboratory_listContainer__Hf61X{
    margin-left: 2vw;
}

.laboratory_expasionPanel__aAk3d{
    width: 30vw;
    position: absolute;
    top: 8vw;
    right: 15vw;
}

.laboratory_textLaboratory__j8xir{
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    color: rgb(85, 85, 85);
    font-size: 3vw;
    text-align: center;
    position: absolute;
    top: 1vw;
    width: 60vw;
    left: 20vw;
}

@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    [class*="textLaboratory"] {
        top: 5vw;
        font-size: 6vw;
        width: 70vw;
        left: 15vw;
    }

    [class*="expasionPanel"] {
        width: 60vw;
        left: 20vw;
        top: 20vw;
    }

    [class*="LaboratoryMicroscope"] {
        display: none;
    }

    
}
.addMedicine_container__8nhpP{
    padding-top: 4vw;
    padding-left: 5vw;
    padding-right: 5vw;
}

.addMedicine_addMedicine__UQIA8{
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    font-size: 3vw;
    color: rgb(85, 85, 85);
    text-align: center;
}

.addMedicine_verticalSpace__GYpdB{
    height: 4vw;
}

.addMedicine_media__20pyQ{
    height: 15vw !important;
}

.addMedicine_cardContainer__pc8IJ{
    flex: 1 1;
    margin-left: 10vw;
}

.addMedicine_textField__3a7Az{
    width: 30vw !important;
}
.addMedicine_textFieldPrice__3kzz2{
    width: 15vw !important;
}

.addMedicine_formContainer__1cKxD{
    flex: 1 1;
    text-align: left;
    margin-left: 10vw;
}

.addMedicine_flexContainer__1U0M_{
    display: flex;
}

.addMedicine_card__wcrRC{
    width: 25vw !important;
}

.addMedicine_button__QwMbe:focus{
    outline: 0 !important;
}

.addMedicine_inputFile__1QECv{
    display: none;
}

.addMedicine_selectImg__q7HdU{
    cursor:pointer;
    background-color: rgb(0, 91, 165);
    width: 12vw;
    height: auto;
    text-align: center;
    border-radius: 2vw;
    padding: 0.5vw;
    color: white;
    text-transform: uppercase;
    box-shadow: -1px 3px 10px #00000067;
}

.addMedicine_selectImg__q7HdU:hover{
    background-color: rgb(0, 72, 131);
}

.addMedicine_saveMedicineContainer__1yWUc{
    text-align: center;
}
.nav_fontFamilyNav__2GB9d{
    font-family: 'Lato', sans-serif;
}
.nav_brandName__23csT{
    margin-left: 0.5vw;
    color: rgb(85, 85, 85);
}

.nav_farmaciaIcon__2x36i{
    width: auto;
    height: 60px;
    margin-top: 0.5vw;
    margin-bottom: 0.5vw;
}

.nav_navlink__2ol16{
    visibility: visible;
}

@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    [class*="navlink"] {
        visibility: hidden;
    }
  }
.exchangeRate_textExchangeRate__3dG2D{
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    color: rgb(85, 85, 85);
    font-size: 3vw;
    text-align: center;
    margin-top: 50px;
}

.exchangeRate_container__3t2uF{
    text-align: center;
    margin-top: 50px;
}

.exchangeRate_textField__2we9b{
    width: 300px;
    margin-right: 50px !important;
}

.exchangeRate_button__2dyR8{
    height: 50px;
    border-radius: 10px !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
