.fontFamilyNav{
    font-family: 'Lato', sans-serif;
}
.brandName{
    margin-left: 0.5vw;
    color: rgb(85, 85, 85);
}

.farmaciaIcon{
    width: auto;
    height: 60px;
    margin-top: 0.5vw;
    margin-bottom: 0.5vw;
}

.navlink{
    visibility: visible;
}

@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    [class*="navlink"] {
        visibility: hidden;
    }
  }